import { ApiPromise, WsProvider } from '@polkadot/api'
import { cryptoWaitReady, blake2AsHex, randomAsU8a } from '@polkadot/util-crypto'
import { formatBalance, BN_TEN, isHex, stringToU8a, u8aToHex, hexToU8a, stringToHex, hexToString } from '@polkadot/util';
import { getCurrentPair } from './index' // 获取kerPair
import BN from 'bn.js'
import { initNetwork } from './index'
import { ExtrinsicStatus, EventRecord } from '@polkadot/types/interfaces';

const node = {
  polkadot: 'wss://rpc.polkadot.io',
  // dbc: 'wss://congtuinfo1.dbcwallet.io:7777', // 聪图云私链测试链

  // dbc: 'wss://infotest.dbcwallet.io:7777', // 公链测试链
  dbc: 'wss://info.lifecompass.cn' // 公链正式链

 // dbc: 'wss://infotest.dbcwallet.io:7777', // 公链测试链

}
let api: ApiPromise | null = null

declare interface Network {
  api: ApiPromise,
}
// 链上交互
export const GetApi = async (): Promise<Network> =>{
  if (!api) {
    const provider = new WsProvider(node.dbc)
    api = await ApiPromise.create({ 
      provider 
    })
  }
  return { api }
}

// 定义信息返回
let CallBack_data = {
  index: 0,
  msg:'',
  section:'',
  success: false
} 
let CallBack_data1 = {
  msg:'',
  success: false
}
// 定义回调函数
const returnFun = (status: ExtrinsicStatus, events: EventRecord[], callback: Function) => {
  if (status.isInBlock) {
    console.log(status, 'status');
    events.forEach(({ event: { method, data: [error] } }) => {
      if (method == 'ExtrinsicFailed') {
        let returnError:any = error
        const decoded = api?.registry.findMetaError(returnError.asModule);
        console.log(JSON.stringify(decoded));
        CallBack_data.msg = decoded!.method;
        CallBack_data.success = false
        CallBack_data.index = decoded!.index;
        CallBack_data.section = decoded!.section;
      }else if(method == 'ExtrinsicSuccess'){
        CallBack_data.msg = method;
        CallBack_data.success = true
      }
    });
    if (callback) {
      callback(CallBack_data)
    }
  }
}


/**
 * getBlockTime 查询链上时间
 * 
 * @return time:链上时间块
 */
export const getBlockTime = async (): Promise<any> => {
  await GetApi();
  let de = await api?.query.system.number();
  return (de as any)?.toHuman();
}

/**
 * getBlockTime 获取链上GPUPointPrice
 * 
 * @return data:返回链上单个算力值的价格
 */
 export const standardGPUPointPrice = async (): Promise<any> => {
  await GetApi();
  let de = await api?.query.onlineProfile.standardGPUPointPrice();
  let data = (de as any)?.toJSON()
  return data
}

/**
 * getBlockTime 获取链上DBC的实时价格
 * 
 * @return data:返回链上单个算力值的价格
 */
 export const dbcPriceOCW = async (): Promise<any> => {
  await GetApi();
  let de = await api?.query.dbcPriceOCW.avgPrice();
  return (de as any)?.toJSON()
}


// export const unhandledReportResult = async (): Promise<any> => {
//   await GetApi()
//   let de = await api?.query.maintainCommittee.reportResult.entries();
//   de?.forEach(([{ args: [era, nominatorId] }, value]) => {
//     console.log(value.toJSON());
//   });
//   // return de
// }
// unhandledReportResult()

/**
 * getAccountBalance 查询账户余额
 * 
 * @return balance:余额
 */
 export const getAccountBalance = async (wallet: string): Promise<any> => {
  await GetApi();
  let de = await api?.query.system.account(wallet);
  return (de as any)?.toJSON();
}

/**
 * getCommitteeList 查询是否为理事会成员
 * 
 * @return list: boolen 理事会成员列表
 */
export const getCommitteeList = async (wallet: string): Promise<any> => {
  await GetApi();
  let de: any = await api!.query.committee.committee()
  let commit = [...de.normal.toHuman(), ...de.fulfilling_list.toHuman()]
  let isCommit = commit.indexOf(wallet) > -1? true : false
  return isCommit
}

// 查询理事会的公钥
export const getCommitteePub = async (wallet: string): Promise<any> => {
  await GetApi();
  let de: any = await api!.query.committee.committeeStake(wallet)
  return de.toHuman()
}

/**
 * ConfirmHash 派单 提交hash信息 
 * @param  ConfirmHash(machine_id, hash)  passward: string 密码（可从vuex中获取）
 * @return callback 回调函数，返回结果信息
 */
 export const ConfirmHash = async (permas: any,  passward: string, callback: (data: Object) => void) => {
  let { machineId, gpuType, gpuNum, cudaCore, gpuMem, calcPoint, sysDisk, dataDisk, cpuType, cpuCoreNum, cpuRate, memNum, randStr, isSupport } = permas
  let raw_input = blake2AsHex(
    machineId
    + gpuType
    + gpuNum
    + cudaCore
    + gpuMem
    + calcPoint
    + sysDisk
    + dataDisk
    + cpuType
    + cpuCoreNum
    + cpuRate
    + memNum
    + randStr
    + isSupport
    , 128)
  await GetApi();
  let kering = await getCurrentPair()
  try {
    await kering!.unlock(passward)
  } catch (e: any) {
    CallBack_data1 = {
      msg: e.message,
      success: false
    };
    callback(CallBack_data1)
    return;
  }
  await cryptoWaitReady();
  await api!.tx.onlineCommittee
  .submitConfirmHash( machineId, raw_input)
  .signAndSend( kering! , ( { events = [], status } ) => {
    returnFun(status, events, callback)
  })
  .catch((res)=>{
    CallBack_data1 = {
      msg: res.message,
      success: false
    };
    callback(CallBack_data1)
  })
}

/**
 * ConfirmRaw 派单 提交原始信息
 * @param permas => machine_info_detail
 * @return callback 回调函数，返回结果信息
 */
export const ConfirmRaw = async (permas: any, passward: string, callback: (arr: Object) => void) => {
  let { machineId, gpuType, gpuNum, cudaCore, gpuMem, calcPoint, sysDisk, dataDisk, cpuType, cpuCoreNum, cpuRate, memNum, randStr, isSupport } = permas
  let machine_info_detail = {
    machineId, 
    gpuType, 
    gpuNum, 
    cudaCore, 
    gpuMem, 
    calcPoint, 
    sysDisk, 
    dataDisk, 
    cpuType, 
    cpuCoreNum, 
    cpuRate,
    memNum, 
    randStr, 
    isSupport
  }
  await GetApi();
  let kering = await getCurrentPair()
  try {
    await kering!.unlock(passward)
  } catch (e: any) {
    CallBack_data1 = {
      msg: e.message,
      success: false
    };
    callback(CallBack_data1)
    return;
  }
  await cryptoWaitReady()
  await api!.tx.onlineCommittee
  .submitConfirmRaw( machine_info_detail )
  .signAndSend( kering! , ( { events = [], status  } ) => {
    returnFun(status, events, callback)
  })
  .catch((res)=>{
    CallBack_data1 = {
      msg: res.message,
      success: false
    };
    callback(CallBack_data1)
  })
}

/**
 * getOrder 获取链上抢单列表
 * 
 * @return [] 抢单列表
 */
export const getOrder = async (): Promise<any> => {
  await GetApi();
  let de = await api?.query.maintainCommittee.liveReport()
  return (de as any)?.toHuman()
}

export const reportInfo = async (ReportId: string): Promise<any> => {
  await GetApi();
  let de = await api?.query.maintainCommittee.reportInfo(ReportId)
  return (de as any)?.toHuman()
}

/**
 * committeeSetBoxPubkey 开始抢单
 * @param publicKey: Uint8Array 公钥
 * @return [] 抢单列表
 */
export const StartGrabbing = async (publicKey: Uint8Array): Promise<any> => {
  await GetApi();
  let de = await api?.tx.committee.committeeSetBoxPubkey(publicKey)
  return de?.toHuman()
}
/**
 * committeeBookReport 开始抢单
 * @param reportid: number 订单号
 * @return [] 抢单列表
 * 
 */
export const bookFaultOrder = async (reportid: number, passward:string, callback: (data: Object) => void) => {
  await GetApi();
  let kering = await getCurrentPair()
  try {
    await kering!.unlock(passward)
  } catch (e: any) {
    CallBack_data1 = {
      msg: e.message,
      success: false
    };
    callback(CallBack_data1)
    return;
  }
  await cryptoWaitReady()
  await api!.tx.maintainCommittee
  .committeeBookReport(reportid)
  .signAndSend( kering! , ( { events = [], status  } ) => {
    returnFun(status, events, callback)
  })
  .catch((res)=>{
    CallBack_data1 = {
      msg: res.message,
      success: false
    };
    callback(CallBack_data1)
  })
}

/**
 * committeeOrder 查询我的抢单列表
 * @param (AccountId)
 */
 export const committeeOrder = async (AccountId: string):Promise<any> => {
  await GetApi();
  let de = await api?.query.maintainCommittee.committeeOrder(AccountId)
  return (de as any)?.toHuman()
}

/**
 * committeeOps 查询抢单机器详细信息
 * @param (AccountId, ReportId)
 */
export const committeeOps = async (AccountId: number, ReportId:number):Promise<any> => {
  await GetApi();
  let de = await api?.query.maintainCommittee.committeeOps(AccountId, ReportId)
  return (de as any)?.toHuman()
}



/**
 * committeeSubmitVerifyHash 抢单 提交hash信息
 * @param  submitConfirmHash(report_id, hash)  passward: string 密码（可从vuex中获取）
 * @return callback 回调函数，返回数组信息
 */
 export const committeeSubmitVerifyHash = async ( permas: any,  passward: string, callback: (data: Object) => void) => {
  let { report_id, committee_rand_str, support_report } = permas
  let raw_input = blake2AsHex(
    report_id
    + committee_rand_str
    + support_report
    , 128)
  await GetApi();
  let kering = await getCurrentPair()
  try {
    await kering!.unlock(passward)
  } catch (e: any) {
    CallBack_data1 = {
      msg: e.message,
      success: false
    };
    callback(CallBack_data1)
    return;
  }
  await cryptoWaitReady();
  console.log(report_id, raw_input, 'raw_input');
  await api!.tx.maintainCommittee
  .committeeSubmitVerifyHash( report_id, raw_input )
  .signAndSend( kering! , ( { events = [], status  } ) => {
    returnFun(status, events, callback)
  })
  .catch((res)=>{
    CallBack_data1 = {
      msg: res.message,
      success: false
    };
    callback(CallBack_data1)
  })
}

/**
 * StartGrabbing 抢单 提交原始信息
 * @param permas
 * @return callback 回调函数，返回数组信息
 */
 export const committeeSubmitInaccessibleRaw = async ( permas: any, passward: string, callback: (data: Object) => void) => {
  let { report_id, committee_rand_str, support_report } = permas
  await GetApi();
  let kering = await getCurrentPair()
  try {
    await kering!.unlock(passward)
  } catch (e: any) {
    CallBack_data1 = {
      msg: e.message,
      success: false
    };
    callback(CallBack_data1)
    return;
  }
  await cryptoWaitReady();
  await api?.tx.maintainCommittee
  .committeeSubmitInaccessibleRaw(report_id, committee_rand_str, support_report )
  .signAndSend( kering! , ( { events = [], status  } ) => {
    returnFun(status, events, callback)
  })
  .catch((res)=>{
    CallBack_data1 = {
      msg: res.message,
      success: false
    };
    callback(CallBack_data1)
  })
}

/**
 * StartGrabbing 抢单 提交hash信息
 * @param  submitConfirmHash(report_id, hash)  passward: string 密码（可从vuex中获取）
 * @return callback 回调函数，返回数组信息
 */
 export const submitConfirmHash = async ( permas: any,  passward: string, callback: (data: Object) => void) => {
  let { report_id, machine_id, reporter_rand_str, committee_rand_str, err_reason, support_report } = permas
  let raw_input = blake2AsHex(machine_id + reporter_rand_str + committee_rand_str + support_report + err_reason, 128)
  await GetApi();
  let kering = await getCurrentPair()
  try {
    await kering!.unlock(passward)
  } catch (e: any) {
    CallBack_data1 = {
      msg: e.message,
      success: false
    };
    callback(CallBack_data1)
    return;
  }
  await cryptoWaitReady();
  await api!.tx.maintainCommittee
  .committeeSubmitVerifyHash( report_id, raw_input )
  .signAndSend( kering! , ( { events = [], status  } ) => {
    returnFun(status, events, callback)
  })
  .catch((res)=>{
    CallBack_data1 = {
      msg: res.message,
      success: false
    };
    callback(CallBack_data1)
  })
}

/**
 * StartGrabbing 抢单 提交原始信息
 * @param permas
 * @return callback 回调函数，返回数组信息
 */
export const submitConfirmRaw = async ( permas: any, passward: string, callback: (data: Object) => void) => {
  let { report_id, machine_id, reporter_rand_str, committee_rand_str, err_reason, extra_err_info, support_report } = permas
  await GetApi();
  let kering = await getCurrentPair()
  try {
    await kering!.unlock(passward)
  } catch (e: any) {
    CallBack_data1 = {
      msg: e.message,
      success: false
    };
    callback(CallBack_data1)
    return;
  }
  await cryptoWaitReady();
  await api?.tx.maintainCommittee
  .committeeSubmitVerifyRaw(report_id, machine_id, reporter_rand_str, committee_rand_str, stringToHex(err_reason), stringToHex(extra_err_info), Number(support_report))
  .signAndSend( kering! , ( { events = [], status  } ) => {
    returnFun(status, events, callback)
  })
  .catch((res)=>{
    CallBack_data1 = {
      msg: res.message,
      success: false
    };
    callback(CallBack_data1)
  })
}


export const reportMachineFault = async ( permas: any, passward: string, callback: (data: Object) => void) => {
  // let type = 'RentedHardwareMalfunction',
  // Hash = '0x2c897da6721be20b23417833bee44aab06f8bf909ae0891ecde64bddc25cadc5',
  // Pub = '0xe8f2957daa5be320b0a384f258da1339a07a0da3605149f4e0ec3a253a42f96c';
  await GetApi();
  let kering = getCurrentPair()
  try {
    kering!.unlock(passward)
  } catch (e: any) {
    CallBack_data1 = {
      msg: e.message,
      success: false
    };
    callback(CallBack_data1)
    return;
  }
  // perams[type] = `(${Hash}, ${Pub})`
  await cryptoWaitReady();
  await api!.tx.maintainCommittee
  .reportMachineFault(permas)
  .signAndSend( kering! , ( { events = [], status  } ) => {
    returnFun(status, events, callback)
  })
  .catch((res)=>{
    CallBack_data1 = {
      msg: res.message,
      success: false
    };
    callback(CallBack_data1)
  })
}


/**
 * leaseCommitteeOps 获取验证人验证的机器
 * @param permas
 * @return callback 回调函数，返回数组信息
 */
export const leaseCommitteeOps = async (wallet: string): Promise<any> => {
  await GetApi();
  let de: any = await api?.query.onlineCommittee.committeeMachine(wallet)
  return de!.toHuman()
}
/**
 * machinesInfo 查询机器的详细信息
 * @param permas
 * @return callback 回调函数，返回数组信息
 */
export const machinesInfo = async ( day: string |number, machineId: string): Promise<any> => {
  await GetApi();
  let de = await api!.query.onlineProfile.machinesInfo(machineId)
  let reward = await erasMachineReleasedReward( day, machineId )
  let data = {
    todayReward: Number(reward),
    info: (de as any)!.toHuman()
  }
  return data
}
/**
 * 查询当前是链上第几天
 * currentEra
*/
export const currentEra = async ():Promise<any> => {
  await GetApi()
  let nowDay: any = await api!.query.onlineProfile.currentEra()
  console.log(nowDay.toHuman(), 'currentEra')
  return nowDay.toHuman()
}

/**
 * 查询机器在指定的某天获得的具体收益
 * erasMachineReleasedReward
*/
export const erasMachineReleasedReward = async ( EraIndex: string|number, MachineId: string ):Promise<any> => {
  await GetApi()
  let totalReward:any = await api!.query.onlineProfile.erasMachineReleasedReward(EraIndex, MachineId)
  return Number(totalReward.toJSON())
}


/**
 * transfer 交易
 * @return callback 回调函数，返回结果信息
 */
// 输入的值转BN
export const inputToBn = (input: string, siPower: BN, basePower: number) => {
  const isDecimalValue = input.match(/^(\d+)\.(\d+)$/);

  let result;

  if (isDecimalValue) {
    const div = new BN(input.replace(/\.\d*$/, ''));
    const modString = input.replace(/^\d+\./, '').substr(0, api?.registry.chainDecimals[0]);
    const mod = new BN(modString);
    result = div
      .mul(BN_TEN.pow(siPower))
      .add(mod.mul(BN_TEN.pow(new BN(basePower - modString.length))));
    console.log('[modString]->', modString)
  } else {
    result = new BN(input.replace(/[^\d]/g, ''))
      .mul(BN_TEN.pow(siPower));
  }

  return result
}
// 转账
export const transfer = async (dest: any, value: string,  passward: string, callback: (data: Object) => void) => {
  await initNetwork();
  const basePower: number = formatBalance.getDefaults().decimals // 小数位数
  console.log(basePower, 'basePower');
  const siPower: BN = new BN(basePower)
  const bob = inputToBn(value, siPower, basePower)
  console.log(bob,'bob');
  let kering = await getCurrentPair()
  try {
    await kering!.unlock(passward)
  } catch (e: any) {
    CallBack_data1 = {
      msg: e.message,
      success: false
    };
    callback(CallBack_data1)
    return;
  }
  await cryptoWaitReady();
  await api!.tx.balances
  .transfer( dest, bob )
  .signAndSend( kering! , ( { events = [], status  } ) => {
    returnFun(status, events, callback)
  })
  .catch((res)=>{
    CallBack_data1 = {
      msg: res.message,
      success: false
    };
    callback(CallBack_data1)
  })
}

// 批量转账
export const batchTransfer = async (walletList: Array<any>, passward: string, callback: (data: Object) => void) => {
  await initNetwork();
  const basePower: number = formatBalance.getDefaults().decimals // 小数位数
  const siPower: BN = new BN(basePower)
  let tsArray: Array<any> = []
  for(let i =0; i< walletList.length; i++){
    let money = `${walletList[i].totalDbc}.${walletList[i].info.nonce}`
    let bob = inputToBn(money, siPower, basePower)
    tsArray.push(api?.tx.balances.transfer( walletList[i].info.wallet, bob ))
  }
  let kering = await getCurrentPair()
  try {
    await kering!.unlock(passward)
  } catch (e: any) {
    CallBack_data1 = {
      msg: e.message,
      success: false
    };
    callback(CallBack_data1)
    return;
  }
  await cryptoWaitReady();
  await api!.tx.utility
  .batch(tsArray)
  .signAndSend( kering! , ( { events = [], status  } ) => {
    if (status.isInBlock) {
      events.forEach(({ event: { method, data: [error] } }) => {
        // console.log(error, method, 'method');
        let returnError: any = error
        if (method == 'ExtrinsicFailed') {
          const decoded = api?.registry.findMetaError(returnError.asModule);
          console.log(JSON.stringify(decoded));
          CallBack_data.msg = decoded!.method;
          CallBack_data.success = false
          CallBack_data.index = decoded!.index;
          CallBack_data.section = decoded!.section;
        }else if(method == 'ExtrinsicSuccess'){
          CallBack_data.msg = method;
          CallBack_data.success = true
        }
      });
      if (callback) {
        callback(CallBack_data)
      }
    }
  })
  .catch((res)=>{
    CallBack_data1 = {
      msg: res.message,
      success: false
    };
    callback(CallBack_data1)
  })
}
